import { useState, useEffect } from 'react';
import Modal from './Modal';
import { FaStar } from 'react-icons/fa';
import { AiOutlineUser } from 'react-icons/ai';
import { FaPhoneVolume } from 'react-icons/fa';
import useDarkMode from '../hooks/useDarkMode';
import '../index.css';

function RatingModal({ setModalOn }) {
    useDarkMode();
    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(0);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [clientName, setClientName] = useState('');
    const [showAdditionalQuestions, setShowAdditionalQuestions] = useState(false);
    const [showReviewForm, setShowReviewForm] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [showSuccess, setShowSuccess] = useState(false);
    const SuccessIndicator = require('react-success-indicator');
    const [reviewText, setReviewText] = useState('');
    const [serviceSpeed, setServiceSpeed] = useState('');
    const [satisfaction, setSatisfaction] = useState('');

 // قفل التمرير عند عرض أي نموذج أو عند وجود بيانات
    useEffect(() => {
        if (rating || hover || phoneNumber || clientName || showAdditionalQuestions || showReviewForm || showSuccess) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto'; // إعادة الوضع الافتراضي عند إلغاء التثبيت
        };
    }, [rating, hover, phoneNumber, clientName, showAdditionalQuestions, showReviewForm, showSuccess]);


    function handleSubmit(e) {
        e.preventDefault();
        if (!phoneNumber || !rating || !clientName) {
            setFormErrors({
                phone: !phoneNumber ? 'الرجاء إدخال رقم الجوال' : '',
                rating: !rating ? 'الرجاء أختيار تقييم للاستمرار' : '',
                name: !clientName ? 'الرجاء إدخال اسم العميل' : '',
            });
            return;
        }

        if (!showAdditionalQuestions) {
            setShowAdditionalQuestions(true);
        } else if (!showReviewForm) {
            setShowReviewForm(true);
        } else {
            setShowSuccess(true);
        }
    }

    useEffect(() => {
        if (showSuccess) {
            setTimeout(() => {
                setModalOn(false);
            }, 3000);
        }
    }, [showSuccess, setModalOn]);

    function handlePrevious() {
        if (showReviewForm) {
            setShowReviewForm(false);
        } else if (showAdditionalQuestions) {
            setShowAdditionalQuestions(false);
        }
    }

    const handleStarClick = (ratingValue) => {
        setRating(ratingValue);
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            rating: '',
        }));
    };

    return (
        <Modal title='ما رأيك في عنب و كوكيز !؟' setModalOn={setModalOn}>
            <div className='modal-content dark:bg-gray-700'>
                <div className='my-4'>
                    <div className='flex justify-center items-center'>
                        {!showSuccess ? (
                            [...Array(5)].map((star, index) => {
                                const ratingValue = index + 1;
                                return (
                                    <label key={index}>
                                        <input
                                            type='radio'
                                            name='rating'
                                            value={ratingValue}
                                            onClick={() => handleStarClick(ratingValue)}
                                            className='hidden' />
                                        <FaStar
                                            className='star'
                                            color={
                                                ratingValue <= (hover || rating)
                                                    ? '#ffc107'
                                                    : '#e4e5e9'
                                            }
                                            size={50}
                                            onMouseEnter={() => setHover(ratingValue)}
                                            onMouseLeave={() => setHover(rating)} />
                                    </label>
                                );
                            })
                        ) : (
                            <div className="flex flex-col items-center justify-center text-main-900 dark:text-white">
                                <div>
                                    <SuccessIndicator size='96px' color='#597c80' className='dark:fill-white' />
                                </div>

                                <p className="mt-4 text-main-900 dark:text-white">
                                    تم التقييم بنجاح شكراً لك
                                </p>
                            </div>
                        )}
                    </div>
                    {formErrors?.rating && (
                        <span className='text-red-500 text-sm mt-1 block text-center pt-5'>
                            {formErrors.rating}
                        </span>
                    )}
                </div>
                <form
                    className={`fixed flex bottom-0 w-full flex-col justify-end gap-3 px-4 rounded-tr-[35px] rounded-tl-[35px] z-[401] py-4 bg-white shadow-[0_1px_6px_rgba(0,0,0,0.5)] animateItems dark:bg-gray-700 ${showReviewForm || showAdditionalQuestions || showSuccess ? 'hidden' : ''}`}
                    onSubmit={handleSubmit}>
                    <div className='flex flex-col text-sm'>
                        <label htmlFor='name' className='relative'>
                            <input
                                type='text'
                                required
                                id='name'
                                name='name'
                                autoComplete='off'
                                maxLength='25'
                                className={`outline-none peer w-full border-b-2 dark:bg-gray-700 dark:text-white focus:border-none h-10 mt-1 px-6 ${formErrors?.name ? 'border-red-500' : ''
                                    }`}
                                onChange={(e) => setClientName(e.target.value)} />
                            <AiOutlineUser className='absolute right-0 top-[50%] translate-y-[-50%] text-gray-400 text-xl peer-focus:text-main-900' />
                            <div className='absolute bottom-0 bg-main-900 h-0.5 w-0 transition-all duration-500 peer-focus:w-full origin-center'></div>
                            <span className='peer-focus:text-main-900 hidden peer-invalid:inline peer-focus:-translate-y-5 peer-focus:translate-x-2 peer-focus:scale-75 absolute text-black/50 right-0 top-3.5 mx-6 px-0.5 bg-white z-20 transition duration-200 dark:bg-gray-700 dark:text-gray-500'>
                                اسم العميل
                            </span>
                        </label>
                        {formErrors?.name && (
                            <span className='text-red-500 text-xs mt-1'>
                                {formErrors.name}
                            </span>
                        )}
                    </div>
                    <div className='flex flex-col text-sm'>
                        <label htmlFor='phone' className='relative'>
                            <input
                                type='number'
                                required
                                id='phone'
                                name='phone'
                                autoComplete='off'
                                pattern='[0-9]*'
                                className={`outline-none peer border-b-2 focus:border-none dark:bg-gray-700 dark:text-white w-full h-10 mt-1 px-6 ${formErrors?.phone ? 'border-red-500' : ''
                                    }`}
                                onChange={(e) => setPhoneNumber(e.target.value)} />
                            <FaPhoneVolume className='absolute right-0 top-[50%] translate-y-[-50%] text-gray-400 text-lg peer-focus:text-main-900' />
                            <div className='absolute bottom-0 bg-main-900 h-0.5 w-0 transition-all duration-500 peer-focus:w-full origin-center'></div>
                            <span className='peer-focus:text-main-900 hidden peer-invalid:inline peer-focus:-translate-y-5 peer-focus:translate-x-2 peer-focus:scale-75 absolute text-black/50 right-0 top-3.5 mx-6 px-0.5 bg-white z-20 transition duration-200 dark:bg-gray-700 dark:text-gray-500'>
                                رقم التواصل
                            </span>
                        </label>
                        {formErrors?.phone && (
                            <span className='text-red-500 text-xs mt-1'>
                                {formErrors.phone}
                            </span>
                        )}
                    </div>

                    <button
                        type='submit'
                        className={`bg-main-900 text-white py-2 w-full rounded-full ${!phoneNumber || !rating || !clientName ? 'opacity-50 cursor-not-allowed' : ''
                            }`}
                    >
                        التالي
                    </button>
                </form>

                {showAdditionalQuestions && !showReviewForm && !showSuccess && (
                    <form
                        className='fixed flex bottom-0 w-full flex-col justify-end gap-3 px-4 rounded-tr-[35px] rounded-tl-[35px] z-[401] py-4 bg-white shadow-[0_1px_6px_rgba(0,0,0,0.5)] animateItems dark:bg-gray-700'
                        onSubmit={handleSubmit}>
                        <div className='flex flex-col text-sm'>
                            <label htmlFor='serviceSpeed' className='relative'>
                                <span className='text-black dark:text-gray-50'>ما مدى سرعة الخدمة؟</span>
                                <select
                                    id='serviceSpeed'
                                    name='serviceSpeed'
                                    value={serviceSpeed}
                                    onChange={(e) => setServiceSpeed(e.target.value)}
                                    className='outline-none peer w-full border-b-2 dark:bg-gray-700 dark:text-white focus:border-none h-10 mt-1 px-2'>
                                    <option value=''>اختر...</option>
                                    <option value='سريعة'>سريعة</option>
                                    <option value='متوسطة'>متوسطة</option>
                                    <option value='بطيئة'>بطيئة</option>
                                </select>
                            </label>
                        </div>

                        <div className='flex flex-col text-sm'>
                            <label htmlFor='satisfaction' className='relative'>
                                <span className='text-black dark:text-gray-50'>هل كانت الخدمة مرضية؟</span>
                                <select
                                    id='satisfaction'
                                    name='satisfaction'
                                    value={satisfaction}
                                    onChange={(e) => setSatisfaction(e.target.value)}
                                    className='outline-none peer w-full border-b-2 dark:bg-gray-700 dark:text-white focus:border-none h-10 mt-1 px-2'>
                                    <option value=''>اختر...</option>
                                    <option value='نعم'>نعم</option>
                                    <option value='لا'>لا</option>
                                </select>
                            </label>
                        </div>
                        <div className='flex justify-between gap-2'>
                            <button
                                type='button'
                                className='bg-gray-300 text-gray-800 py-2 rounded-full flex-1 dark:bg-gray-900 dark:text-white dark:border-[#111827]'
                                onClick={handlePrevious}>
                                السابق
                            </button>
                            <button
                                type='submit'
                                className={`bg-main-900 text-white py-2 rounded-full w-1/2 ${!serviceSpeed || !satisfaction ? 'opacity-50 cursor-not-allowed' : ''}`}
                                disabled={!serviceSpeed || !satisfaction}
                            >
                                التالي
                            </button>
                        </div>
                    </form>
                )}

                {showReviewForm && !showSuccess && (
                    <form
                        className='fixed flex bottom-0 w-full flex-col justify-end gap-3 px-4 rounded-tr-[35px] rounded-tl-[35px] z-[401] py-4 bg-white shadow-[0_1px_6px_rgba(0,0,0,0.5)] animateItems dark:bg-gray-700'
                        onSubmit={handleSubmit}>
                        <span className='text-black text-sm dark:text-gray-50 '>ملاحظاتك وتقييمك :</span>
                        <textarea
                            className='border border-gray-300 rounded text-sm p-2 h-32 focus:outline-none focus:border-[#597c80] focus:ring-1 focus:ring-[#597c80] text-[#000000] dark:bg-gray-900 dark:text-white'
                            placeholder='أخبرنا أكثر عن تجربتك ...'
                            required={true}
                            value={reviewText}
                            onChange={(e) => setReviewText(e.target.value)}
                        ></textarea>
                        <div className='flex justify-between gap-2'>
                            <button
                                type='button'
                                className='bg-gray-300 text-gray-800 py-2 rounded-full flex-1 dark:bg-gray-900 dark:text-white dark:border-[#111827]'
                                onClick={handlePrevious}>
                                السابق
                            </button>
                            <button
                                type='submit'
                                className={`bg-main-900 text-white py-2 rounded-full flex-1 ${reviewText.trim() === '' ? 'opacity-50 cursor-not-allowed' : ''
                                    }`}
                                disabled={reviewText.trim() === ''}
                                onClick={() => setShowSuccess(true)}
                            >
                                إرسال
                            </button>
                        </div>
                    </form>
                )}
            </div>
        </Modal>
    );
}

export default RatingModal;
